import React from 'react'
import CustomDialog from './customDialog'
import StoreUtils from '../../utils/storeUtils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectChatError } from '../../reducers/uiSlice'

export const ChatErrorDialog = () => {
  const { t } = useTranslation()
  const { showDialog, errorMessage, errorTitle } = useSelector(selectChatError)
  const handleOnClose = () => {
    StoreUtils.reloadWindow()
  }

  const getDescription = () => {
    return t(errorMessage)
  }

  return (
    <CustomDialog
      tittle={t(errorTitle)}
      description={getDescription()}
      onClose={handleOnClose}
      isOpen={showDialog}
    />
  )
}
