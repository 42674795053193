import React, { useEffect, useState } from 'react'
import { Filter } from 'bad-words'
import PropTypes from 'prop-types'
import { Messages } from './Messages'
import { ChatInput } from './ChatInput'
import { ChatHeader } from './ChatHeader'
import {
  selectIsOnCall,
  selectUsersCount,
  selectTermsAndConditionsAccepted,
  resetMessagesCount,
  selectSeller,
} from '../../reducers/callSlice'
import qs from 'qs'
import UtmUtils from '../../utils/utmUtils'
import { useDispatch, useSelector } from 'react-redux'
import { ChatContainer } from './style'
import {
  selectAutoJoinChat,
  selectChatCooldown,
  selectChatMuted,
  setDisabledByCooldown,
  setShowAskNameDialog,
} from '../../reducers/uiSlice'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import { ActionBar } from '../Call/ActionBar'
import { Row } from '../Call/Layout.styles'
import { ChatLoader } from './ChatLoader'
import { ChatAccordion } from './ChatAccordion'
import { DefaultBuyerName, useAuth } from '@gojiraf/auth'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { useUtm } from '@gojiraf/useutm'
import CallService from '../../services/callService'
import { useAmplifyChat, sendMessage, getNextPage } from '@gojiraf/chat'
import { badWords } from '../../constants/badWords'

export const ChatModule = ({ isDesktop = false, chatOpen, setChatOpen }) => {
  const { user } = useAuth()
  const [chatConnected, setChatConnected] = useState(false)
  const [loadingChatConnection, setLoadingChatConnection] = useState(false)
  const isOnCall = useSelector(selectIsOnCall)
  const { state: chatState, dispatch: chatDispatch } = useAmplifyChat()
  const { id: sellerId } = useSelector(selectSeller)
  const getChannelId = async () => await CallService.findEventId(sellerId)
  const [isFetchingMoreMessages, setIsFetchingMoreMessages] = useState(false)
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { showUIComponents } = useUtm(utm_medium)
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const chatCooldown = useSelector(selectChatCooldown)
  const autoJoinChat = useSelector(selectAutoJoinChat)
  const chatMuted = useSelector(selectChatMuted)
  const {
    storeConfigurations: {
      features: {
        chat: { allowCancel, askNameOnCallStart, showMessageField },
      },
    },
  } = useSelector(selectCurrentStore)
  const termsAndConditionsAccepted = useSelector(selectTermsAndConditionsAccepted)
  const usersCount = useSelector(selectUsersCount)
  const filter = new Filter({ list: badWords })

  const handleClick = async () => {
    gaEventTracker('InCall > Chat', 'click-button-enabled-chat-d')
    matomoTrackEvent('InCall > Chat', 'click-button-enabled-chat-d')
    await initChat()
  }

  useEffect(() => {
    const askNameForChat = () => {
      if (user.name === DefaultBuyerName && !chatMuted && askNameOnCallStart && showUIComponents) {
        dispatch(
          setShowAskNameDialog({
            open: true,
            allowCancel,
          }),
        )
      }
    }

    if (termsAndConditionsAccepted && chatConnected) askNameForChat()
  }, [termsAndConditionsAccepted, chatConnected])

  const toggleChatMobile = (toggle) => {
    setChatOpen(toggle)
    dispatch(resetMessagesCount())
  }

  async function initChat() {
    console.debug({ isOnCall })
    if (isOnCall === false) return
    setLoadingChatConnection(true)

    const eventId = await getChannelId()
    console.log({ eventId })
    setLoadingChatConnection(false)
    if (!eventId) return

    chatDispatch({
      type: 'setEvent',
      payload: {
        eventId,
      },
    })

    chatDispatch({
      type: 'connect',
    })

    setChatConnected(true)
  }

  async function handleSendMessage({ message }) {
    if (filter.isProfane(message.toLowerCase())) return

    if (chatCooldown > 0) {
      dispatch(setDisabledByCooldown(true))
      setTimeout(() => {
        dispatch(setDisabledByCooldown(false))
      }, chatCooldown * 1000)
    }

    await sendMessage(chatDispatch, chatState, {
      chatId: chatState.eventId,
      text: message,
      role: 'BUYER',
      username: user.name,
      replyId: null,
      userId: user.id,
    })
  }

  async function fetchMoreMessages() {
    setIsFetchingMoreMessages(true)
    await getNextPage(chatDispatch, chatState.eventId, chatState.nextToken)
    setIsFetchingMoreMessages(false)
  }

  useEffect(() => {
    console.debug({ autoJoinChat })
    if (autoJoinChat === true) {
      initChat()
    }
  }, [autoJoinChat])

  return (
    <ChatContainer
      isDesktop={isDesktop}
      isLoading={!chatConnected}
      chatOpen={chatOpen}
      data-test="chat-container"
    >
      {isDesktop === true && <ChatHeader usersCount={usersCount} />}
      {!chatConnected ? (
        <ChatLoader
          handleClick={handleClick}
          loading={loadingChatConnection}
          isDesktop={isDesktop}
        />
      ) : (
        <>
          <Messages
            isDesktop={isDesktop}
            hasMoreMessages={chatState.nextToken !== null}
            fetchMoreMessages={fetchMoreMessages}
            isFetchingMoreMessages={isFetchingMoreMessages}
            toggleChatMobile={toggleChatMobile}
            user={user}
            messages={chatState.messages}
            chatOpen={chatOpen}
            pinnedMessage={chatState.pin}
          >
            {!isDesktop && <ChatAccordion chatOpen={chatOpen} />}
          </Messages>
          {showMessageField && (
            <Row
              style={{ gap: '0.312rem', padding: isDesktop ? '0' : '0 1rem', alignItems: 'center' }}
            >
              <ChatInput isDesktop={isDesktop} sendMessage={handleSendMessage} />
              {!isDesktop && <ActionBar />}
            </Row>
          )}
        </>
      )}
    </ChatContainer>
  )
}

ChatModule.propTypes = {
  isDesktop: PropTypes.bool,
  chatOpen: PropTypes.bool,
  setChatOpen: PropTypes.func,
  user: PropTypes.object.isRequired,
}
