import React, { useEffect } from 'react'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import { TopNavBarButton } from '../GJKit/Buttons/TopNavBarButton'
import PropTypes from 'prop-types'

const FullScreenToggleButton = ({ isFullscreen, setIsFullscreen }) => {
  const toggleFullscreen = async () => {
    // Todo el documento
    if (!isFullscreen) {
      await getFullscreenElement(document.body)()
    } else {
      await exitFullScreenElement(document)()
    }
  }

  const getFullscreenElement = (element) => {
    return (
      element.requestFullscreen?.bind(element) ||
      element.webkitRequestFullscreen?.bind(element) ||
      element.mozRequestFullScreen?.bind(element) ||
      element.msRequestFullscreen?.bind(element)
    )
  }

  const exitFullScreenElement = (document) => {
    return (
      document.exitFullscreen?.bind(document) ||
      document.webkitExitFullscreen?.bind(document) ||
      document.mozCancelFullScreen?.bind(document) ||
      document.msExitFullscreen?.bind(document)
    )
  }

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    //Cuando entras en modo de pantalla completa con F11, el navegador no utiliza la API de Fullscreen
    const handleKeyDown = (event) => {
      if (event.key === 'F11') {
        event.preventDefault()
      }
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', handleFullscreenChange)
    document.addEventListener('msfullscreenchange', handleFullscreenChange)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange)
      document.removeEventListener('msfullscreenchange', handleFullscreenChange)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      {getFullscreenElement(document.body) ? (
        <div>
          <TopNavBarButton
            id="full-screen-call-button"
            data-test="full-screen-call"
            onClick={toggleFullscreen}
          >
            {!isFullscreen ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
          </TopNavBarButton>
        </div>
      ) : null}
    </>
  )
}

FullScreenToggleButton.propTypes = {
  isFullscreen: PropTypes.func.isRequired,
  setIsFullscreen: PropTypes.func.isRequired,
}

export { FullScreenToggleButton }
