/* eslint-disable no-case-declarations */
import AgoraRTC, { RemoteStreamType } from 'agora-rtc-sdk-ng'
import { useAuthStore } from '@gojiraf/auth'
import { AgoraRoles } from '../constants/agoraRoles'
import { EventLogs } from '../constants/eventLogs'
import store from '../core/store'
import {
  setSellerNetworkError,
  setBuyerPoorNetwork,
  callFinishedHandler,
} from '../reducers/callSlice'
import { http } from './callClient'
import { FacingModesStates } from '../constants/cameraFacingModes'
import NotificationsService from './notifications/index'
import { CallNotifications } from '../constants/callNotifications'

AgoraRTC.setLogLevel(process.env.REACT_APP_AGORA_LOG_LEVEL ?? 4)
AgoraRTC.enableLogUpload()

class CallService {
  constructor() {
    this.rtcClient = null
    this.rtcUID = null
    this.remoteAudioTracks = []
    this.sellerAudioTrack = null
    this.sellerVideoTrack = null
    this.externalAudioTrack = null
    this.externalVideoTrack = null
    this.currentVideoResolution = null
    this.notificationService = null

    if (!CallService.instance) {
      this._map = new Map()
      this.eventHandlers = {}
      CallService.instance = this
    }
    return CallService.instance
  }

  async getSeller(storeId) {
    const { tokens } = useAuthStore.getState().user

    const response = await http.post(
      '/call',
      {
        storeId,
      },
      {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      },
    )
    return response.data
  }

  async findEventId(sellerId) {
    const { tokens } = useAuthStore.getState().user

    try {
      const {
        data: { eventId = null },
      } = await http.post(
        '/getEventId',
        {
          sellerId,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
          timeout: 5000,
        },
      )
      return eventId
    } catch (error) {
      console.error(error)
      return null
    }
  }

  on(event, handler) {
    if (!this.eventHandlers[event]) {
      this.eventHandlers[event] = []
    }
    this.eventHandlers[event].push(handler)
  }

  off(event, handler) {
    const index = this.eventHandlers[event]?.indexOf(handler)
    if (index >= 0) {
      this.eventHandlers[event].splice(index, 1)
    }
  }

  dispatchEvent(event, params) {
    this.eventHandlers[event]?.forEach((handler) => handler(...params))
  }

  callActions() {
    return this._map.get('callActions')
  }

  get dispatch() {
    return store.dispatch
  }

  async setVideoResolution(videoResolution) {
    const dualStreamMode =
      store.getState().store.current.storeConfigurations.features.dualStreamMode
    if (dualStreamMode === true) {
      try {
        this.currentVideoResolution = videoResolution
        console.log(`stream ${videoResolution === 0 ? 'high' : 'low'} quality`)
        await Promise.all([
          this.rtcClient.setRemoteVideoStreamType(this._getSeller().rtcUID, videoResolution),
          this.rtcClient.setRemoteVideoStreamType(this._getCohost().rtcUID, videoResolution),
          this.rtcClient.setRemoteVideoStreamType(this._getExternalVideo().rtcUID, videoResolution),
        ])
      } catch (error) {
        console.error('quality', error)
      }
    }
  }

  restartAudioTracks() {
    for (let audioTrack of this.remoteAudioTracks) {
      audioTrack.stop()
      audioTrack.play()
    }
  }

  setVideoStartedHandler(videoStarted) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onVideoStart: videoStarted,
    })
  }

  setCallProductsHandler(callsProducts) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onCallProductsUpdated: callsProducts,
    })
  }

  setLikeMessageReceivedHandler(handleMeesage) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onLikeMessageReceived: handleMeesage,
    })
  }

  setExternalReactionReceived(handleMeesage) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onExternalReactionReceived: handleMeesage,
    })
  }

  setQuickAddReceived(handleMessage) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onQuickAddReceived: handleMessage,
    })
  }

  setFinishEventCountdownMessageHandler(handleMeesage) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onFinishEventCountMessage: handleMeesage,
    })
  }

  setStartEventCountdownMessageHandler(handleMeesage) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onStartEventCountMessage: handleMeesage,
    })
  }

  setChatCooldownMessageHandler(handleMeesage) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onChatCooldownMessage: handleMeesage,
    })
  }

  setDisplayPopUpMessageHandler(handleMeesage) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onDisplayPopUpMessage: handleMeesage,
    })
  }

  setClearProductsInCallHandler(clearProductsInCallHandler) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onClearProductsInCall: clearProductsInCallHandler,
    })
  }

  setMuteUserChatHandler(cb) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onMuteUserChat: cb,
    })
  }

  setOnMessageAdded(cb) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onMessageAdded: cb,
    })
  }

  setOnMessagePinned(cb) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onMessagePinned: cb,
    })
  }

  setOnMessageDeleted(cb) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onMessageDeleted: cb,
    })
  }

  setOnMessagesReceived(cb) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onMessagesReceived: cb,
    })
  }

  setOnCallFinished(cb) {
    const actions = this.callActions()
    this._map.set('callActions', {
      ...actions,
      onCallFinished: cb,
    })
  }

  async joinNotificationsChannel(channelId) {
    try {
      //Notifications service
      this.notificationService = NotificationsService.getInstance(channelId)
      this.notificationService.subscribe((notification) => {
        this.onNotificationReceived(notification)
      })
    } catch (error) {
      console.error(error)
    }
  }

  onNotificationReceived(notification) {
    const { event, payload } = notification
    const payloadParsed = JSON.parse(payload)
    switch (event) {
      case CallNotifications.LIKE:
        this.callActions().onLikeMessageReceived()
        break
      case CallNotifications.SHOW_REACTIONS:
        this.callActions().onExternalReactionReceived(payloadParsed)
        break
      case CallNotifications.FINISH_EVENT_COUNTDOWN:
        this.callActions().onFinishEventCountMessage(payloadParsed)
        break
      case CallNotifications.START_EVENT_COUNTDOWN:
        this.callActions().onStartEventCountMessage(payloadParsed)
        break
      case CallNotifications.SHOW_POPUP:
        this.callActions().onDisplayPopUpMessage(payloadParsed, true)
        break
      case CallNotifications.HIDE_POPUP:
        this.callActions().onDisplayPopUpMessage(
          {
            title: '',
            description: '',
            couponCode: '',
          },
          false,
        )
        break
      case CallNotifications.SHOW_QUICK_ADD:
        this.callActions().onQuickAddReceived(true)
        break
      case CallNotifications.HIDE_QUICK_ADD:
        this.callActions().onQuickAddReceived(false)
        break
      case CallNotifications.EVENT_FINISHED:
        this.leaveCall()
        this.dispatchEvent(CallServiceEvents.SELLER_HUNG_UP, [EventLogs.SELLER_HANG_UP_ALL])
        break
      case CallNotifications.MUTE_USER_CHAT:
        this.callActions().onMuteUserChat(payloadParsed)
        break
      case CallNotifications.SLOW_MODE_CHAT:
        this.callActions().onChatCooldownMessage(payloadParsed)
        break
      default:
        break
    }
  }

  async joinRTCChannel(args) {
    const dualStreamMode =
      store.getState().store.current.storeConfigurations.features.dualStreamMode
    try {
      const isHost = !args.isOneToManySale || args.isCohostUser
      this._initRTCClient({
        host: isHost,
      })
      if (isHost && dualStreamMode) this.rtcClient.enableDualStream()
      await this.subscribeRtcClientUserJoined()
      await this.subscribeRtcClientUserLeft()
      this.subscribeRtcReconnection()
      const userUid = args.isCohostUser ? this._getCohost().rtcUID : null
      const rtcUID = await this.rtcClient.join(args.appId, args.channel, null, userUid)
      this.rtcUID = rtcUID
    } catch (error) {
      console.error(error)
    }
  }

  _initRTCClient({ host }) {
    this.rtcClient = AgoraRTC.createClient({
      mode: host ? 'rtc' : 'live',
      codec: 'vp8',
      role: host ? AgoraRoles.HOST : AgoraRoles.AUDIENCE,
      clientRoleOptions: { level: 1 },
    })
  }

  renderVideo(eventDispatch, mediaStream) {
    this.dispatchEvent(eventDispatch, [mediaStream])
  }

  async startLocalVideo(isMobile, isCohostUser) {
    if (isCohostUser) return await this.startCohostCamera()
    return await this.startBuyerCamera(isMobile)
  }

  async getCamerasDevices() {
    return await AgoraRTC.getCameras()
  }

  async startCohostCamera() {
    const configPreset = {
      facingMode: FacingModesStates.ENVIROMENT,
    }
    const agoraCamera = await AgoraRTC.createCameraVideoTrack(configPreset)
    return agoraCamera
  }

  async startBuyerCamera(isMobile) {
    const configPreset = {
      encoderConfig: {
        width: isMobile ? 640 : 240,
        height: isMobile ? 480 : 320,
        frameRate: 15,
        bitrateMin: isMobile ? 450 : 150,
        bitrateMax: isMobile ? 550 : 200,
      },
    }
    const agoraCamera = await AgoraRTC.createCameraVideoTrack(configPreset)
    return agoraCamera
  }

  startLocalAudio() {
    return AgoraRTC.createMicrophoneAudioTrack()
  }

  publishTrack(track) {
    return this.rtcClient.publish(track)
  }

  async getOtherBuyers() {
    const buyers = {}
    if (this.rtcClient) {
      for (const user of this.rtcClient.remoteUsers) {
        if (user.uid === this._getSeller().rtcUID) continue
        buyers[user.uid] = user
      }
    }
    return buyers
  }

  subscribeToVideo(buyer) {
    return this.rtcClient.subscribe(buyer, 'video')
  }

  subscribeToAudio(buyer) {
    return this.rtcClient.subscribe(buyer, 'audio')
  }

  setUser(username) {
    this._map.set('user', {
      userName: username,
      id: this.rtcClient.uid.toString(),
      uid: this.rtcClient.uid.toString(),
    })
  }

  subscribeRtcReconnection() {
    this.rtcClient.on('media-reconnect-end', (userUID) => {
      const { hasVideo, externalVideoTrack } = this.getExternalVideoOnGoing()
      if (userUID === this._getSeller().rtcUID && hasVideo) {
        setTimeout(async () => {
          await this.changeVideoTrack(externalVideoTrack)
          this.sellerAudioTrack?.stop()
          this.externalAudioTrack?.play()
        }, 3000)
      }
    })
  }

  async subscribeRtcClientUserJoined() {
    this.rtcClient.on('user-published', async (user, mediaType) => {
      await this.rtcClient.subscribe(user, mediaType)
      if (mediaType === 'audio') {
        this.remoteAudioTracks.push(user.audioTrack)
      }
      if (user.uid === this._getSeller().rtcUID) {
        const { hasVideo, externalVideoTrack } = this.getExternalVideoOnGoing()
        if (mediaType === 'video') {
          this.sellerVideoTrack = user.videoTrack
          this.renderVideo(CallServiceEvents.SELLER_PUBLISHED_CAMERA, user.videoTrack)
          if (externalVideoTrack) await this.changeVideoTrack(externalVideoTrack)
          this.dispatch(setSellerNetworkError(false))
        }
        if (mediaType === 'audio') {
          this.sellerAudioTrack = user.audioTrack
          if (!hasVideo) user.audioTrack.play()
        }
      } else if (user.uid === this._getExternalVideo().rtcUID) {
        if (mediaType === 'video') {
          this.externalVideoTrack = user.videoTrack
          if (this.sellerVideoTrack) await this.changeVideoTrack(user.videoTrack)
        }
        if (mediaType === 'audio') {
          this.externalAudioTrack = user.audioTrack
          this.sellerAudioTrack?.stop()
          user.audioTrack.play()
        }
      } else {
        if (mediaType === 'audio') {
          this.dispatchEvent(CallServiceEvents.BUYER_PUBLISHED_MICROPHONE, [
            user.uid,
            user.audioTrack,
          ])
        }
        if (mediaType === 'video') {
          this.dispatchEvent(CallServiceEvents.BUYER_PUBLISHED_CAMERA, [user.uid, user.videoTrack])
          user.videoState = true
        }
      }
    })

    this.rtcClient.on('user-unpublished', async (user, mediaType) => {
      if (user.uid !== this._getSeller().rtcUID && user.uid !== this._getExternalVideo().rtcUID) {
        if (mediaType === 'audio') {
          this.dispatchEvent(CallServiceEvents.BUYER_UNPUBLISHED_MICROPHONE, [user.uid])
        }
        if (mediaType === 'video') {
          this.dispatchEvent(CallServiceEvents.BUYER_UNPUBLISHED_CAMERA, [user.uid])
          user.videoState = false
        }
      } else if (user.uid === this._getExternalVideo().rtcUID) {
        if (mediaType === 'video') {
          await this.changeVideoTrack(this.sellerVideoTrack)
          this.sellerAudioTrack?.play()
        }
      }
    })
    this.rtcClient.on('network-quality', async (stats) => {
      if (stats.downlinkNetworkQuality > 2) {
        this.dispatch(setBuyerPoorNetwork(true))
        if (this.currentVideoResolution !== RemoteStreamType.LOW_STREAM) {
          this.setVideoResolution(RemoteStreamType.LOW_STREAM)
        }
      } else {
        if (this.currentVideoResolution !== RemoteStreamType.HIGH_STREAM) {
          this.setVideoResolution(RemoteStreamType.HIGH_STREAM)
        }
      }
      console.log('network-quality: ' + stats.downlinkNetworkQuality)
    })
    this.rtcClient.on('user-joined', async (user) => {
      this.dispatchEvent(CallServiceEvents.BUYER_JOINED, [user])
    })
    this.rtcClient.on('connection-state-change', async (state) => {
      if (state === 'DISCONNECTED') {
        const { isOnCall } = store.getState().call
        if (isOnCall) {
          this.notificationService.unsubscribe()
          store.dispatch(callFinishedHandler())
        }
      }
    })
  }

  getCurrentVideoTrack() {
    const { hasVideo } = this.getExternalVideoOnGoing()
    if (hasVideo) return this.externalVideoTrack
    return this.sellerVideoTrack
  }

  async changeVideoTrack(videoTrack) {
    this.currentVideoTrack = videoTrack
    const parentVideoElement = document.querySelector('[id="video__streaming"] div')
    const videoElement = document.querySelector('[id="video__streaming"] video')
    const mediaStream = new MediaStream([videoTrack.getMediaStreamTrack()])
    videoElement.srcObject = mediaStream
    parentVideoElement.id = `agora-video-player-${videoTrack.getTrackId()}`
    videoElement.id = `video_${videoTrack.getTrackId()}`
    await videoElement.play()
  }

  createVideoElement(videoTrack) {
    videoTrack.play('video__streaming', { fit: 'cover' })
  }

  async getCallMembersCount() {
    // const channelId = this.rtmChannel.channelId
    // const { [channelId]: membersCount } = await this.rtmClient.getChannelMemberCount([channelId])

    //TODO: Recuperar la cantidad de usuario conectados al servicio de notificaciones
    const membersCount = 0
    const { sellerIsOnCall } = store.getState().call

    const seller = sellerIsOnCall ? 1 : 0
    const buyers = membersCount - seller
    const otherBuyers = buyers - 1
    const total = membersCount
    return {
      seller,
      buyers,
      otherBuyers,
      total,
    }
  }

  getExternalVideoOnGoing() {
    const rtcUsers = this.rtcClient.remoteUsers
    const externalVideoUser = rtcUsers.find((user) => user.uid === this._getExternalVideo().rtcUID)
    return {
      hasVideo: externalVideoUser?.hasVideo,
      externalVideoTrack: externalVideoUser?.videoTrack ?? this.externalVideoTrack,
    }
  }

  async subscribeRtcClientUserLeft() {
    this.rtcClient.on('user-left', (user, reason) => {
      if (user.uid === this._getSeller().rtcUID) {
        if (reason === 'ServerTimeOut') {
          this.dispatch(setSellerNetworkError(true))
        }
      } else {
        this.dispatchEvent(CallServiceEvents.BUYER_LEFT, [user.uid])
      }
    })
  }

  async notifyChannelLikeMessage() {
    const msg = {
      event: CallNotifications.LIKE,
    }
    await this.notificationService.sendMessage(msg)
  }

  async leaveCall() {
    if (this.callActions()?.onCallFinished) {
      this.callActions().onCallFinished()
    }
    if (this.callActions()?.onClearProductsInCall) {
      this.callActions().onClearProductsInCall()
    }
    await this.unsubscribeNotifications()
    await this.rtcClient.leave()
    this._map.clear()
  }

  async unsubscribeNotifications() {
    await this.notificationService.unsubscribe()
  }

  _getExternalVideo() {
    const state = store.getState()
    return state.call.externalVideo
  }
  _getSeller() {
    const state = store.getState()
    return state.call.seller
  }

  _getCohost() {
    const state = store.getState()
    return state.call.cohost
  }
}

export const CallServiceEvents = {
  SELLER_PUBLISHED_CAMERA: 'SELLER_PUBLISHED_CAMERA',
  SELLER_HUNG_UP: 'SELLER_HUNG_UP',
  BUYER_JOINED: 'BUYER_JOINED',
  BUYER_LEFT: 'BUYER_LEFT',
  BUYER_PUBLISHED_CAMERA: 'BUYER_PUBLISHED_CAMERA',
  BUYER_UNPUBLISHED_CAMERA: 'BUYER_UNPUBLISHED_CAMERA',
  BUYER_PUBLISHED_MICROPHONE: 'BUYER_PUBLISHED_MICROPHONE',
  BUYER_UNPUBLISHED_MICROPHONE: 'BUYER_UNPUBLISHED_MICROPHONE',
}

const instance = new CallService()

export default instance
