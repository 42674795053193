export const CallNotifications = {
  LIKE: 'LIKE',
  FINISH_EVENT_COUNTDOWN: 'FINISH_EVENT_COUNTDOWN',
  START_EVENT_COUNTDOWN: 'START_EVENT_COUNTDOWN',
  SHOW_POPUP: 'SHOW_POPUP',
  HIDE_POPUP: 'HIDE_POPUP',
  SHOW_REACTIONS: 'SHOW_REACTIONS',
  SHOW_QUICK_ADD: 'SHOW_QUICK_ADD',
  HIDE_QUICK_ADD: 'HIDE_QUICK_ADD',
  EVENT_FINISHED: 'EVENT_FINISHED',
  MUTE_USER_CHAT: 'MUTE_USER_CHAT',
  SLOW_MODE_CHAT: 'SLOW_MODE_CHAT',
}
