import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useBeforeunload } from 'react-beforeunload'
import Snackbar from '@material-ui/core/Snackbar'
import qs from 'qs'
import UtmUtils from '../utils/utmUtils'
import {
  selectDisplayFinishEventCountdownstate,
  selectDisplayPopUpState,
  selectDisplayStartEventCountdownstate,
  selectSellerNetworkError,
  setDisplayFinishEventCountdown,
  setDisplayPopUpState,
  setDisplayStartEventCountdown,
} from '../reducers/callSlice'
import {
  selectIsNotificationVisible,
  selectNotificationMessage,
  setLikeMessageReceivedHandler,
  setMuteUserChatHandler,
  setVideoStartedHandler,
  setVideoDimensions,
  setIsNotificationVisible,
  showOnCloseMessageCall,
  setFinishEventCountdownMessageHandler,
  setDisplayPopUpMessageHandler,
  setStartEventCountdownMessageHandler,
  setRemoteLikeAnimation,
  setExternalReactionReceivedHandler,
  setExternalReaction,
  setFinishEventServerDateTime,
  setQuickAdd,
  setQuickAddReceivedHandler,
  setEventStartServerDateTime,
  setChatCooldownMessageHandler,
  setChatCooldown,
} from '../reducers/uiSlice'
import { PIPBackground } from './GJKit/PIPBackground'
import { usePIP } from '../hooks/usePIP'
import { isIOS } from 'react-device-detect'
import { CallLayout } from './Call/Layout'
import { Streaming } from './Call/Streaming'
import { PhoneContainer } from './Kit/Container'
import styled from 'styled-components'
import { Cards as CallCards } from './Call/Cards'
import { CheckoutIframeDialog } from './dialogs/CheckoutIframeDialog'
import CustomDialog from './dialogs/customDialog'
import { useTranslation } from 'react-i18next'
import { PoorNetworkDialog } from './dialogs/PoorNetworkDialog'
import { useViews } from 'state'
import { Products } from './Products/Products'
import { useLocation } from 'react-router-dom'
import { selectCurrentStore } from '../reducers/storeSlice'
import { useDevices } from '@gojiraf/responsive'
import { usePopUp } from '../hooks/usePopUp'
import { getDateTime } from '../utils/countdownTimerUtils'
import { useUtm } from '@gojiraf/useutm'
import { useAmplifyChat } from '@gojiraf/chat'
import { useAuth } from '@gojiraf/auth'

export default function CallCard({ onCallFinished, toggleMicrophone, toggleCamera }) {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { isDesktop } = useDevices()
  const dispatch = useDispatch()
  const { dispatch: chatDispatch } = useAmplifyChat()
  const { data: popUpData, setData: setPopUpData } = usePopUp()
  const displayFinishEventCountdown = useSelector(selectDisplayFinishEventCountdownstate)
  const displayStartEventCountdown = useSelector(selectDisplayStartEventCountdownstate)
  const {
    storeConfigurations: {
      features: { popUpCohostVideo },
    },
  } = useSelector(selectCurrentStore)
  const displayPopUpState = useSelector(selectDisplayPopUpState)
  const sellerNetworkError = useSelector(selectSellerNetworkError)
  const [sellerNetworkErrorAcknowledged, setSellerNetworkErrorAcknowledged] = useState(false)
  const { isEnabled } = usePIP()
  const { state } = useViews()
  const location = useLocation()
  const isCohostUser = location.state?.isCohostUser
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { utm_medium } = UtmUtils.getUtmObject(queryParams)
  const { showUIComponents } = useUtm(utm_medium)
  useBeforeunload(() => t('homePage.useBeforeUnload'))

  useEffect(() => {
    function popstateHandler() {
      closeCall()
    }

    window.addEventListener('popstate', popstateHandler)
    return function () {
      window.removeEventListener('popstate', popstateHandler)
    }
  }, [])

  const closeCall = () => {
    dispatch(showOnCloseMessageCall())
  }

  const onLikeMessageReceived = () => {
    dispatch(setRemoteLikeAnimation({ remoteLikeAnimation: true }))
  }

  const onExternalReactionReceived = (message) => {
    dispatch(setExternalReaction({ externalReaction: message.reaction }))
  }

  const onFinishEventCountMessage = (message) => {
    const { activateFinishEventCountdown, secondsToAddFinishCountdown } = message
    dispatch(setFinishEventServerDateTime(getDateTime(secondsToAddFinishCountdown)))
    dispatch(setDisplayFinishEventCountdown(activateFinishEventCountdown))
  }
  const onQuickAddReceived = (message) => {
    dispatch(setQuickAdd({ quickAdd: message }))
  }
  const onStartEventCountMessage = (message) => {
    const { activateStartEventCountdown, secondsToAddStartCountdown } = message
    dispatch(setEventStartServerDateTime(getDateTime(secondsToAddStartCountdown)))
    dispatch(setDisplayStartEventCountdown(activateStartEventCountdown))
  }

  const onDisplayPopUpMessage = (popUpData, show) => {
    setPopUpData(popUpData)
    dispatch(setDisplayPopUpState(show))
  }

  const onVideoStarted = (mediaTrack) => {
    const settings = mediaTrack.getSettings()
    dispatch(
      setVideoDimensions({
        width: settings.height,
        height: settings.width,
        aspectRatio: settings.aspectRatio,
      }),
    )
  }

  const handleClose = () => {
    dispatch(setIsNotificationVisible({ isNotificationVisible: false }))
  }

  const onMuteUserChat = ({ userId }) => {
    if (user.id === userId) {
      chatDispatch({
        type: 'setUserMuted',
        payload: {
          muted: true,
        },
      })
    }
  }

  const onChatCooldownReceived = ({ seconds }) => {
    console.debug('prueba', seconds)
    dispatch(setChatCooldown(seconds))
  }

  useEffect(() => {
    dispatch(setLikeMessageReceivedHandler(onLikeMessageReceived))
    dispatch(setExternalReactionReceivedHandler(onExternalReactionReceived))
    dispatch(setVideoStartedHandler(onVideoStarted))
    dispatch(setQuickAddReceivedHandler(onQuickAddReceived))
    dispatch(setMuteUserChatHandler(onMuteUserChat))
    dispatch(setChatCooldownMessageHandler(onChatCooldownReceived))
  }, [dispatch])

  useEffect(() => {
    dispatch(setFinishEventCountdownMessageHandler(onFinishEventCountMessage))
  }, [displayFinishEventCountdown])

  useEffect(() => {
    dispatch(setStartEventCountdownMessageHandler(onStartEventCountMessage))
  }, [displayStartEventCountdown])

  useEffect(() => {
    dispatch(setDisplayPopUpMessageHandler(onDisplayPopUpMessage))
  }, [displayPopUpState])

  const render = () => {
    if (isDesktop) {
      return null
    }

    if (state.matches('main.showingCart') === true) {
      return <Products />
    } else if (state.matches('main.showingOrder') === true) {
      return <CallCards.Checkout onCallFinished={onCallFinished} />
    } else if (state.matches('main.showingOrderStatus') === true) {
      return <CallCards.CashCheckoutFinish />
    } else if (state.matches('main.showingProducts') === true) {
      return <CallCards.ProductDetail />
    }
  }

  return (
    <>
      <PhoneContainer data-test="main-container-video">
        {isEnabled && !isIOS && <PIPBackground />}
        <CustomDialog
          tittle={t('homePage.phoneContainerCustomDialogTitle')}
          description={t('homePage.phoneContainerCustomDialogDescription')}
          onClose={() => {
            setSellerNetworkErrorAcknowledged(true)
          }}
          isOpen={sellerNetworkError && !sellerNetworkErrorAcknowledged}
        />
        {showUIComponents && <PoorNetworkDialog />}
        <Notification
          open={useSelector(selectIsNotificationVisible)}
          autoHideDuration={3000}
          onClose={handleClose}
          data-test="main-container-notification"
        >
          <span>{useSelector(selectNotificationMessage)}</span>
        </Notification>

        {render}

        <CallLayout
          popUpData={popUpData}
          onCallFinished={onCallFinished}
          toggleMicrophone={toggleMicrophone}
          toggleCamera={toggleCamera}
        />
        <Streaming
          blurStreaming={displayFinishEventCountdown || displayStartEventCountdown}
          isCohostUser={isCohostUser}
          popUpCohostVideo={popUpCohostVideo}
        />
      </PhoneContainer>
      <CheckoutIframeDialog />
    </>
  )
}

const Notification = styled(Snackbar)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: unset;
  top: 0;
  z-index: 3;
  background-color: white;
  color: black;
  transform: none;
  padding: 1.5rem 0;
`

CallCard.propTypes = {
  onCallFinished: PropTypes.func.isRequired,
  toggleMicrophone: PropTypes.func,
  toggleCamera: PropTypes.func,
}
