"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  default: () => src_default,
  translations: () => translations
});
module.exports = __toCommonJS(src_exports);

// src/i18n.ts
var import_i18next = __toESM(require("i18next"));
var import_react_i18next = require("react-i18next");

// src/translates/es/global.json
var global_default = {
  cart: {
    addButton: "Agregar",
    emptyCart: "Tu carrito est\xE1 vacio",
    goToCart: "Comprar",
    goToCartButton: "Ir al carrito",
    goToCartCencosudParis: "Agregar a mi carro",
    goToCartChildren: "Comprar",
    cart: "Carrito de Compras",
    cartToAgree: "Carrito",
    continue: "continuar",
    onAddClickedShowMessage: "Seleccione una opci\xF3n",
    parisAddButton: "Ver Producto",
    productHeader: "Producto",
    productOptionError: "Selecciona el detalle del producto",
    pay: "pagar",
    payToAgree: "iniciar orden",
    total: "total",
    selectLabel: "Variante",
    "home delivery": "a domicilio",
    "store pickup": "retiro en tienda",
    styledSelectLabel: "Variante",
    variantButton: "Agregar"
  },
  chat: {
    accept: "Acepto los",
    askJoinButton: "\xA1Participa de nuestra sala de chat y deja tus comentarios!",
    buttonCancel: "Cancelar",
    buttonConfirm: "Confirmar",
    chatCooldown: "Enviando mensaje...",
    chatMuted: "Has sido silenciado",
    contentSubtitle: "Comparte tu nombre o nickname",
    contentTitle: "\xDAnete al chat",
    deletedMessage: "Este mensaje fue eliminado.",
    errorCheckbox: "Requiere Aceptar T\xE9rminos y Condiciones",
    errorMessageRequiredInput: "Este campo es requerido",
    errorMessageOffensive: "El nombre ingresado es ofensivo",
    formatErrorEmail: "Ingrese un formato v\xE1lido",
    inputEmailPlaceholder: "Email",
    inputNationalIdPlaceholder: "Documento",
    inputNicknamePlaceholder: "Nombre o Nickname",
    inputOptionalText: " (Opcional)",
    inputPlaceholder: "Comenta aqu\xED",
    join: "Unirme al chat",
    localBuyer: "T\xFA",
    mainHeading: "Participantes",
    nationalIdErrorLength: "Debe tener entre 2 y 25 caracteres",
    termsAndConditions: "T\xE9rminos y Condiciones"
  },
  checkout: {
    and: "y",
    auxiliarHeading: "\xBFEntre qu\xE9 calles est\xE1?",
    buttonError: "Reintentar",
    controllerAcceptTermsAndConditions: "Acepto ",
    controllerTermsAndConditions: "T\xE9rminos de uso del evento",
    controllerTermsAndConditionsError: "Debe aceptar los T\xE9rminos y Condiciones",
    essenCheckoutSuccessText: "Gracias por vivir la experiencia Live Shopping",
    gojirafText: "Gracias por vivir la experiencia de Live Shopping con",
    inputLabelAddress: "Calle",
    inputLabelApartment: "Depto",
    inputLabelCity: "Localidad",
    inputLabelFloor: "Piso",
    inputLabelFloorApartment: "Piso - Depto",
    inputLabelName: "Nombre y Apellido",
    inputLabelNumber: "N\xFAmero",
    inputLabelPhoneCode: "C\xF3digo de \xC1rea",
    inputLabelPhoneNumber: "Tel\xE9fono de contacto",
    inputLabelPostalCode: "C\xF3digo Postal",
    inputLabelProvince: "Provincia",
    inputLabelRegion: "Provincia/Estado",
    inputLastName: "Apellido",
    inputName: "Nombre",
    labelCash: "Efectivo/Transferencia",
    labelCheckbox: "Recordar mis datos",
    labelPaypalMP: "Paga con el medio de pago que prefieras",
    labelToAgree: "Acordar con el vendedor",
    legendAccountMoney: "Dinero en Cuenta",
    legendCreditCard: "Tarjeta de Cr\xE9dito o D\xE9bito",
    mainHeader: "Pago Finalizado",
    mainHeaderDelivery: "Env\xEDo",
    mainHeaderError: "Pago Rechazado",
    mainHeaderTakeaway: "Retiro en Tienda",
    mainHeaderToAgree: "Datos de contacto",
    required: "Este campo es obligatorio",
    secondaryHeadingDelivery: "Datos de env\xEDo a domicilio",
    secondaryHeadingDetail: "Detalle de compra",
    secondaryHeadingDetailToAgree: "Detalle de orden",
    secondaryHeadingTakeaway: "Datos de facturaci\xF3n",
    secondaryHeadingToAgreeOne: "Ingresa tus datos de contacto.",
    secondaryHeadingToAgreeTwo: " El m\xE9todo de pago y la entrega es a convenir.",
    shippingPrice: "Costo de env\xEDo",
    stockErrorEssen: "Disculpe las molestias. Nos hemos quedado sin stock.",
    stockErrorTwoEssen: "Por favor contacta a tu emprendedor amigo para coordinar la compra.",
    stockErrorTN: "Disculpe las molestias. Nos hemos quedado sin stock.",
    stockErrorTNTwo: "Explora otras opciones disponibles en el cat\xE1logo de productos.",
    postalCodeError: "El c\xF3digo postal es incorrecto. Int\xE9ntelo nuevamente con los siguientes c\xF3digos: ",
    summaryLine: "Monto",
    takeawayText: "Una vez finalizado el proceso de pago, recibir\xE1s el comprobante de compra v\xEDa email.",
    text: "Gracias por vivir la experiencia de Live Shopping con ",
    textCash: "Recibir\xE1s un email para coordinar el pago.",
    textCashError: "Ha ocurrido un problema.",
    textError: "Ha ocurrido un error con los datos cargados.",
    textErrorTwo: "Int\xE9ntalo nuevamente",
    textToAgree: "Recibir\xE1s un email para coordinar el m\xE9todo de pago y la entrega.",
    toAgreeHeader: "Orden confirmada",
    thanksCash: "\xA1Gracias por tu compra!",
    thanksToAgree: "\xA1Gracias por realizar tu orden!",
    wrongEmailFormat: "Por favor, ingresa un correo electr\xF3nico v\xE1lido",
    linxRedirecting: "Un momento, por favor...",
    fillUserData: "Completa tus datos",
    wrongResellerNumberFormat: "N\xFAmero de cuenta inv\xE1lido",
    wrongZoneNumberFormat: "N\xFAmero de zona inv\xE1lido"
  },
  countdown: {
    addToCalendarButton: "Agregar a mi calendario",
    eventName: "Live Shopping",
    eventDescriptionTextOne: "Sumate al evento de Live Shopping de",
    eventDescriptionTextTwo: "Vas a poder conocer m\xE1s acerca de los productos, hacer consultas y comprar en el momento.",
    finishEventTimerHeader: "Las compras siguen un rato m\xE1s.",
    finishEventTimerSecondaryHeader: "Cierran En:",
    monthNames: {
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre"
    },
    startEventTimerHeader: "Ya estamos por comenzar en:"
  },
  dialogs: {
    acceptButton: "Aceptar",
    alternateHeadingError: "Ha ocurrido un problema con el pago.",
    alternateHeadingPending: "Pago pendiente de confirmaci\xF3n.",
    alternateHeadingSuccess: "Gracias por vivir la experiencia de Live Shopping con ",
    autoPlayUnlocker: {
      dialogContentText: "Tocar para activar el sonido"
    },
    blockUser: "Bloquear Usuario",
    browserDescription: "Por favor, intente utilizando Safari.",
    browserTitle: "Browser No Soportado",
    buyerPoorNetworkDescription: "Tu conexi\xF3n es inestable. Es posible que la aplicaci\xF3n no funcione correctamente.",
    cancel: "Volver al evento",
    cancelButton: "Cancelar",
    cannotDeleteIsPinned: "Los mensajes fijados no pueden ser eliminados",
    cannotDeleteHasAnswers: "Los mensajes con respuestas no pueden ser eliminados",
    closeButton: "Cerrar",
    configurationHeader: "Configuraci\xF3n",
    configurationSubtitle: "Moderador",
    configurationActionItemOne: "Habilitar Pop Up Promo",
    configurationActionItemTwo: "Habilitar Counter Inicio",
    configurationActionItemThree: "Habilitar Counter Cierre",
    confirm: "Salir",
    content: "\xBFEst\xE1s seguro que quieres salir del evento?",
    customDialog: "\xA1El vendedor ha finalizado la llamada!",
    deniedPermitsTitle: "Permisos denegados",
    dialogTitle: "Finaliza tu compra",
    goBackButton: "Volver a la llamada",
    goToCheckout: "Ser\xE1s redirigido al ecommerce de {{storeName}} para continuar con el proceso",
    joinChatErrorDescription: "Vuelva a cargar el evento.",
    joinChatErrorDescriptionModerator: " Vuelva a cargar el evento o comuniquese con soporte t\xE9cnico e indique el siguiente c\xF3digo.",
    leaveButton: "Salir del Evento",
    muteUser: "Silenciar Usuario",
    payPalDialog: "Ser\xE1s redirigido a una ventana emergente para completar tu pago con PayPal.",
    pendingPermitsTitle: "Permisos denegados",
    permitsDescription: "GoJiraf necesita permisos para acceder al micr\xF3fono o camara, para poder comunicarse con la tienda",
    termsAndConditionsBr: "Lee los T\xE9rminos y Condiciones y toca aceptar para continuar.",
    termsAndConditionsBrTitle: "T\xE9rminos y Condiciones"
  },
  errorMessage: {
    defaultErrorMessageTitle: "Se ha producido un error inesperado en el chat.",
    defaultErrorMessage: "Ocurrio un error.",
    joinErrorMessage: "Vuelva a cargar el evento.",
    pinErrorMessage: "Ocurrio un error al fijar el mensaje. Intente nuevamente.",
    muteErrorMessage: "Ocurrio un error al silenciar al usuario. Intente nuevamente.",
    sendMessageError: "Ocurrio un error al enviar el mensaje. Intente nuevamente.",
    badWord: "Tu mensaje no se ha podido enviar porque infringe nuestros t\xE9rminos y condiciones, ya que que queremos que la comunicaci\xF3n se realice de manera respetuosa.",
    badWordTitle: "Mensaje no enviado",
    unpinMessageError: "Ocurrio un error al desfijar el mensaje. Intente nuevamente.",
    deletedChannel: "Occurrio un error inesperado con el chat \u{1F614}\u{1F622} | Internal Server Error - Status 500 - getStream"
  },
  homePage: {
    calling: "Llamando",
    countdownTimerDays: "D\xEDas",
    countdownTimerHours: "Horas",
    countdownTimerMinutes: "Minutos",
    countdownTimerSeconds: "Segundos",
    defaultError: "Ups, ocurri\xF3 un error vuelve a intentarlo mas tarde",
    errorTypography: "Error en el servidor",
    join: "Ingresar",
    lengthErrorName: "El nombre debe tener entre 2 y 25 caracteres",
    loadercontainerTypography: "\xA1Ya est\xE1s por ingresar!",
    micAndCamContainer: {
      parragraphCaseOne: "Podr\xE1s activarla cuando desees",
      parragraphCaseTwo: "Podr\xE1s activarlo cuando desees",
      parragraphDefault: "Podr\xE1s activarlos cuando desees",
      textCaseOne: "Tu c\xE1mara y micr\xF3fono est\xE1n activados",
      textCaseThree: "Tu microfono est\xE1 desactivado",
      textCaseTwo: "Tu c\xE1mara est\xE1 desactivada",
      textDefault: "Tu c\xE1mara y micr\xF3fono est\xE1n desactivados"
    },
    moderator: "Moderaci\xF3n del Chat",
    cohost: "Invitado",
    nickname: "Comparte tu nombre o nickname",
    nicknameLabel: "Nombre o Nickname",
    phoneContainerCustomDialogTitle: "Tu personal Shopper se ha desconectado",
    phoneContainerCustomDialogDescription: "Parece que tu personal shopper esta teniendo problemas de conexion. Puedes seguir esperandolo si cierras este mensaje.",
    pin: "PIN",
    pinForgotten: "\xBFOlvidaste el PIN?",
    pinLabel: "PIN Moderador",
    pinLabelCoHost: "PIN Cohost",
    profaneName: "Nombre inapropiado",
    sellerNotAvailable: {
      additionalText: "\xA1No te pierdas los pr\xF3ximos eventos de ",
      button: "Volver",
      customLink: "Contactanos",
      customLinkOTM: "Tienda Online",
      eventIsFullTitle: "El evento ha llegado al m\xE1ximo de participantes",
      eventStartedParagraph: "Actualiza la p\xE1gina o haz click en el siguiente bot\xF3n y no te pierdas de vivir una experiencia de Live Shopping en vivo",
      eventStartedTitle: "\xA1El evento ya comenz\xF3!",
      eventNotStartedParagraph: "Siguenos en nuestras redes sociales y enterate de los pr\xF3ximos eventos de Live Shopping",
      eventNotStartedTitle: "En este momento no hay un evento en curso",
      goToEvent: "Ir al evento",
      goToHome: "Ir a la home",
      link: "D\xE9janos tus datos y te contactaremos a la brevedad",
      linkOTM: "Te invitamos a que sigas comprando en nuestra tienda online",
      meetingFullHeading: "La reuni\xF3n ha llegado al m\xE1ximo de participantes.",
      meetingFullHeadingOTM: "Gracias por conectarte",
      meetingfullParagraphOTM: "El evento ha llegado al m\xE1ximo de participantes",
      phoneNumber: "Escribenos por Whatsapp para coordinar una cita",
      phoneNumberAndLink: "Escr\xEDbenos por Whatsapp o dejanos tus datos y te contactaremos",
      sellerNotAvailableHeading: "En este momento no te podemos atender.",
      whatsappButton: "Whatsapp"
    },
    subtitle: "\xA1Ya est\xE1s por ingresar!",
    useBeforeUnload: "Al cerrar la p\xE1gina finalizar\xE1 la llamada!"
  },
  moderatorPage: {
    wrongPin: "Pin invalido"
  },
  products: {
    buy: "comprar",
    continue: "continuar",
    from: "desde",
    installments: "cuotas de",
    highlightProduct: "destacar",
    "more variants": "m\xE1s opciones",
    noResultsFound: "Tu b\xFAsqueda no coincidi\xF3 con ning\xFAn resultado.",
    products: "Productos",
    productsList: "Lista de Productos",
    productListEmpty: "A\xFAn no se han cargado productos",
    searchBar: "Buscar productos",
    viewOtherProducts: "ver otros productos",
    viewProduct: "ver m\xE1s",
    addToCart: "Agregar"
  },
  pdp: {
    add: "agregar",
    addToCart: "Agregar al carrito",
    checkout: "Comprar",
    addToAgree: "Agregar al carrito",
    description: "Descripci\xF3n del producto",
    errorMessageVariants: "Selecciona para continuar",
    freeShipping: "Env\xEDo gratis a todo el pa\xEDs",
    lightIntegration: "ver m\xE1s",
    products: "Producto"
  },
  uiElements: {
    copyToClipboard: "Copiado al portapapeles",
    shareMobile: "Compartido exitosamente",
    PIPBackground: "El video se esta reproduciendo en modo imagen sobre imagen (PIP)"
  },
  form: {
    satisfactionEvent: {
      eventStore: "Tienda del evento",
      company: "Company",
      send: "Enviar",
      reset: "Borrar Formulario",
      passLeyend: "Nunca env\xEDes contrase\xF1as a trav\xE9s de Formularios de Google.",
      leyend: "Este formulario se cre\xF3 en GoJiraf\xAE.",
      forms: "Formularios",
      placeHolderResponse: "Escribe tu respuesta aqu\xED",
      errorMessage: "Este campo es obligatorio"
    }
  }
};

// src/translates/en/global.json
var global_default2 = {
  cart: {
    addButton: "Add",
    emptyCart: "Your Cart is Empty",
    goToCart: "Purchase",
    goToCartButton: "Go to Cart",
    goToCartCencosudParis: "Add to my cart",
    goToCartChildren: "Purchase",
    cart: "Cart",
    onAddClickedShowMessage: "Select an option",
    parisAddButton: "See product",
    productHeader: "Product",
    productOptionError: "Choose a product variant",
    pay: "pay",
    total: "total",
    selectLabel: "Variant",
    "home delivery": "home delivery",
    "store pickup": "store pickup",
    styledSelectLabel: "Variant",
    variantButton: "Add"
  },
  chat: {
    accept: "Accept",
    askJoinButton: "Participate in our chat room and leave your comments!",
    buttonCancel: "Cancel",
    buttonConfirm: "Confirm",
    chatCooldown: "Sending message...",
    chatMuted: "You have been muted",
    contentSubtitle: "Share your name or nickname",
    contentTitle: "Join the chat",
    deletedMessage: "This message was deleted.",
    errorCheckbox: "Requires Accept Terms and Conditions",
    errorMessageRequiredInput: "This field is required",
    errorMessageOffensive: "The name is offensive",
    formatErrorEmail: "Enter a valid format",
    inputEmailPlaceholder: "Email",
    inputNationalIdPlaceholder: "Document",
    inputNicknamePlaceholder: "Name or Nickname",
    inputOptionalText: " (Optional)",
    inputPlaceholder: "Comment here",
    join: "Join the chat",
    localBuyer: "You",
    mainHeading: "Participants",
    nationalIdErrorLength: "Must have between 2 and 25 characters",
    termsAndConditions: "Terms and Conditions"
  },
  checkout: {
    and: "and",
    auxiliarHeading: "Between which streets?",
    buttonError: "Try again",
    controllerAcceptTermsAndConditions: "I accept ",
    controllerTermsAndConditions: "Terms and Conditions",
    controllerTermsAndConditionsError: "You must accept the Terms and Conditions",
    essenCheckoutSuccessText: "Thanks for living the liveshopping experience",
    gojirafText: "Thanks for living the liveshopping experience with",
    inputLabelAddress: "Address",
    inputLabelApartment: "N/A",
    inputLabelCity: "City",
    inputLabelFloor: "N/A",
    inputLabelFloorApartment: "N/A",
    inputLabelName: "Name and Surname",
    inputLabelNumber: "Number",
    inputLabelPhoneCode: "Area Code",
    inputLabelPhoneNumber: "Phone Number",
    inputLabelPostalCode: "Zip Code",
    inputLabelProvince: "Province",
    inputLabelRegion: "State",
    inputLastName: "Lastname",
    inputName: "Name",
    labelCash: "Cash/Bank Transfer",
    labelCheckbox: "Remember my information",
    labelPaypalMP: "Use the payment method of your preference",
    legendAccountMoney: "Money in Account",
    legendCreditCard: "Credit or Debit Card",
    mainHeader: "Payment completed",
    mainHeaderDelivery: "Delivery",
    mainHeaderError: "Payment declined",
    mainHeaderTakeaway: "Store Pickup",
    required: "Mandatory field",
    secondaryHeadingDelivery: "Shipping information",
    secondaryHeadingDetail: "Purchase detail",
    secondaryHeadingTakeaway: "Billing information",
    shippingPrice: "Shipping price",
    stockErrorEssen: "Disculpe las molestias. Nos hemos quedado sin stock.",
    stockErrorTwoEssen: "Por favor contacta a tu emprendedor amigo para coordinar la compra.",
    stockErrorTN: "Disculpe las molestias. Nos hemos quedado sin stock.",
    stockErrorTNTwo: "Explora otras opciones disponibles en el cat\xE1logo de productos.",
    postalCodeError: "El c\xF3digo postal es incorrecto. Int\xE9ntelo nuevamente con los siguientes c\xF3digos: ",
    summaryLine: "Total Amount",
    takeawayText: "Once the payment process is finished, you will receive the proof of purchase via email.",
    text: "Thank you for joining the Live Shopping Experience with ",
    textCash: "You are going to recieve an e-mail to coordinate the payment",
    textCashError: "A problem has occurred.",
    textError: "A problem has occurred with the payment.",
    textErrorTwo: "Try again",
    thanksCash: "Thank you for shopping!",
    wrongEmailFormat: "Please enter a valid email address",
    linxRedirecting: "A moment, please...",
    fillUserData: "Fill in your details",
    wrongResellerNumberFormat: "Invalid account number",
    wrongZoneNumberFormat: "Invalid zone number"
  },
  countdown: {
    addToCalendarButton: "Add to my calendar",
    eventName: "Live Shopping",
    eventDescriptionTextOne: "Join [BRAND]'s Live Shopping event",
    eventDescriptionTextTwo: "You will be able to learn more about the products, make inquiries and purchase during the event.",
    finishEventTimerHeader: "The Live Event continues a little longer",
    finishEventTimerSecondaryHeader: "Event finishes in:",
    monthNames: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December"
    },
    startEventTimerHeader: "We are about to begin in:"
  },
  dialogs: {
    acceptButton: "Accept",
    alternateHeadingError: "A problem has occurred with the payment",
    alternateHeadingPending: "Pending payment confirmation.",
    alternateHeadingSuccess: "Thank you for joining the Live Shopping Experience with ",
    autoPlayUnlocker: {
      dialogContentText: "Tap to activate sound"
    },
    blockUser: "Block User",
    browserDescription: "Please, try using Safari.",
    browserTitle: "Unsupported Browser",
    buyerPoorNetworkDescription: "Your connection is unstable. App might be working incorrectly.",
    cancel: "Back to event",
    cancelButton: "Cancel",
    cannotDeleteIsPinned: "Pinned messages can't be deleted",
    cannotDeleteHasAnswers: "Messages with replies can't be deleted",
    closeButton: "Close",
    configurationHeader: "Settings",
    configurationSubtitle: "Moderator",
    configurationActionItemOne: "Enable Pop Up Promotion",
    configurationActionItemTwo: "Enable Start Event Counter",
    configurationActionItemThree: "Enable Finish Event Counter",
    confirm: "Exit",
    content: "Are you sure you want to exit the LiveShopping event?",
    customDialog: "The seller has ended the call!",
    deniedPermitsTitle: "Permits Denied",
    dialogTitle: "Finalize your purchase",
    goBackButton: "Back to Liveshopping",
    goToCheckout: "You will be redirected to {{storeName}}'s ecommerce to continue the process.",
    joinChatErrorDescription: "Reload the event.",
    joinChatErrorDescriptionModerator: " Reload the event or contact support and enter the following code.",
    leaveButton: "Leave event",
    muteUser: "Mute User",
    payPalDialog: "You will be redirected to a pop-up window to complete your payment with PayPal.",
    pendingPermitsTitle: "Pending Permits",
    permitsDescription: "GoJiraf needs permission to access microphone",
    termsAndConditionsBr: "Read the Terms and Conditions and accept to continue.",
    termsAndConditionsBrTitle: "Terms and Conditions"
  },
  errorMessage: {
    defaultErrorMessageTitle: "An unexpected error has ocurred in the chat.",
    defaultErrorMessage: "An error has ocurred.",
    joinErrorMessage: "Reload the event.",
    pinErrorMessage: "An error has ocurred while pinning the message. Try again.",
    muteErrorMessage: "An error has ocurred while muting the user. Try again.",
    sendMessageError: "An error has ocurred while sending the message. Try again.",
    badWord: "Your message could not be sent because it violates our terms and conditions, since we want communication to be carried out in a respectul manner.",
    badWordTitle: "Message not sent",
    unpinMessageError: "An error has ocurred while unpinning the message. Try again.",
    deletedChannel: "An unexpected error occurred with chat \u{1F614}\u{1F622} | Internal Server Error - Status 500 - getStream"
  },
  homePage: {
    calling: "Calling",
    countdownTimerDays: "Days",
    countdownTimerMinutes: "Minutes",
    countdownTimerHours: "Hours",
    countdownTimerSeconds: "Seconds",
    defaultError: "Oops, has occurred an error, try again later",
    errorTypography: "Server error",
    join: "Join",
    lengthErrorName: "Name must have between 2 and 25 characters",
    loadercontainerTypography: "You're about to join!",
    micAndCamContainer: {
      parragraphCaseOne: "You can activate it whenever you want",
      parragraphCaseTwo: "You can activate it whenever you want",
      parragraphDefault: "You can activate them whenever you want",
      textCaseOne: "Your cam and mic are activated",
      textCaseThree: "Your microphone is deactivated",
      textCaseTwo: "Your cam is deactivated",
      textDefault: "Your cam and microphone are deactivated"
    },
    moderator: "Chat Moderation",
    cohost: "Guest",
    nickname: "Share your name or nickname",
    nicknameLabel: "Name or Nickname",
    phoneContainerCustomDialogDescription: "It seems that your Personal Shopper is having connectivity problems. You can keep waiting if you close this message.",
    phoneContainerCustomDialogTitle: "Your personal Shopper has logged out",
    pin: "PIN",
    pinForgotten: "Did you forget your PIN?",
    pinLabel: "Moderator PIN",
    profaneName: "Innapropiate Name",
    sellerNotAvailable: {
      additionalText: "\xA1Don't miss upcoming events",
      button: "Go Back",
      customLink: "Contact us",
      customLinkOTM: "Online Store",
      eventIsFullTitle: "The event has reached the maximum number of participants",
      eventStartedParagraph: "Refresh the page or click the button below and don't miss out on experiencing a live Live Shopping event",
      eventStartedTitle: "The event has already started!",
      eventNotStartedParagraph: "Follow us on social media to stay updated on upcoming Live Shopping events",
      eventNotStartedTitle: "Right now there's no live event happening",
      goToEvent: "Go to the event",
      goToHome: "Go to the home page",
      link: "Leave us a message and we'll get in touch with you shortly",
      linkOTM: "We invite you to continue shopping in our online store",
      meetingFullHeading: "The meeting has reached the maximum number of participants.",
      meetingFullHeadingOTM: "Thank you for connnecting",
      meetingfullParagraphOTM: "The event has reached the maximum number of participants",
      phoneNumber: "Make an appointment",
      phoneNumberAndLink: "Text us via Whatsapp or leave us a message and we'll get in touch",
      sellerNotAvailableHeading: "We are busy right now. Please try again later",
      whatsappButton: "Via Whatsapp"
    },
    subtitle: "You're about to join!",
    useBeforeUnload: "When closing this page the call will end!"
  },
  moderatorPage: {
    wrongPin: "Wrong Pin"
  },
  products: {
    buy: "buy",
    from: "from",
    installments: "installments of",
    highlightProduct: "highlight",
    moreVariants: "more options",
    noResultsFound: "No matching results found.",
    products: "Products",
    productsList: "Products List",
    productListEmpty: "There are no products yet",
    searchBar: "Search products",
    viewOtherProducts: "view other products",
    viewProduct: "view more",
    addToCart: "Add"
  },
  pdp: {
    add: "add",
    addToCart: "Add",
    checkout: "Buy",
    description: "Product description",
    errorMessageVariants: "Select to continue",
    freeShipping: "Free shipping nationwide",
    lightIntegration: "see more",
    products: "Product"
  },
  uiElements: {
    copyToClipboard: "Copied to clipboard",
    shareMobile: "Successfully shared",
    PIPBackground: "Video is playing in picture-in-picture mode (PIP)"
  },
  form: {
    satisfactionEvent: {
      eventStore: "Event Store",
      company: "Company",
      send: "Send",
      reset: "Reset form",
      passLeyend: "Never submit passwords through Google Forms.",
      leyend: "This form was created in GoJiraf\xAE.",
      forms: "Forms",
      placeHolderResponse: "Write your answer here",
      errorMessage: "This field is required"
    }
  }
};

// src/translates/pt/global.json
var global_default3 = {
  cart: {
    addButton: "Adicionar",
    emptyCart: "Seu carrinho est\xE1 vazio",
    goToCart: "Comprar",
    goToCartButton: "Ir ao carrinho",
    goToCartCencosudParis: "Adicionar ao meu carrinho",
    goToCartChildren: "Comprar",
    cart: "Carrinho",
    onAddClickedShowMessage: "Selecione uma op\xE7\xE3o",
    parisAddButton: "Ver Produto",
    productHeader: "Produto",
    productOptionError: "Selecione o detalhe do produto",
    pay: "pagar",
    total: "total",
    selectLabel: "Variante",
    "home delivery": "entrega em domic\xEDlio",
    "store pickup": "retirada na loja",
    styledSelectLabel: "Variante",
    variantButton: "Adicionar"
  },
  chat: {
    accept: "Aceito os",
    askJoinButton: "\xA1Participe do nosso chat e deixe seus coment\xE1rios!",
    buttonCancel: "Cancelar",
    buttonConfirm: "Confirmar",
    chatCooldown: "Enviando mensagem...",
    chatMuted: "Voc\xEA foi mutado",
    contentSubtitle: "Compartilhe seu nome ou apelido",
    contentTitle: "Entre no bate-papo",
    deletedMessage: "This message was deleted.",
    errorCheckbox: "Requer a aceita\xE7\xE3o dos Termos e Condi\xE7\xF5es",
    errorMessageRequiredInput: "Este campo \xE9 obrigat\xF3rio",
    errorMessageOffensive: "O nome inserido \xE9 ofensivo",
    formatErrorEmail: "Inserir um formato v\xE1lido",
    inputEmailPlaceholder: "E-mail",
    inputNationalIdPlaceholder: "Documento",
    inputNicknamePlaceholder: "Nome ou apelido",
    inputOptionalText: " (Opcional)",
    inputPlaceholder: "Comente aqui",
    join: "Participar do chat",
    localBuyer: "Voc\xEA",
    mainHeading: "Participantes",
    nationalIdErrorLength: "Deve ter entre 2 e 25 caracteres",
    termsAndConditions: "Termos e Condi\xE7\xF5es"
  },
  checkout: {
    and: "e",
    auxiliarHeading: "Entre quais ruas fica?",
    buttonError: "Tente novamente",
    controllerAcceptTermsAndConditions: "Aceito ",
    controllerTermsAndConditions: "Termos e Condi\xE7\xF5es",
    controllerTermsAndConditionsError: "Deve aceitar os Termos e Condic\xF5es",
    essenCheckoutSuccessText: "Obrigado por viver a experi\xEAncia Live Shopping",
    gojirafText: "Obrigado por viver a experi\xEAncia de Live Shopping com",
    inputLabelAddress: "Endere\xE7o",
    inputLabelApartment: "Andar",
    inputLabelCity: "Cidade",
    inputLabelFloor: "Piso",
    inputLabelFloorApartment: "Complemento",
    inputLabelName: "Nome e Sobrenome",
    inputLabelNumber: "N\xFAmero",
    inputLabelPhoneCode: "C\xF3digo de \xC1rea",
    inputLabelPhoneNumber: "Telefone de contato",
    inputLabelPostalCode: "Cep",
    inputLabelProvince: "Cidade",
    inputLabelRegion: "Cidade/Estado",
    inputLastName: "Sobrenome",
    inputName: "Nome",
    labelCash: "Dinheiro/Transfer\xEAncia",
    labelCheckbox: "Lembrar meus dados",
    labelPaypalMP: "Pague com a forma de pagamento que preferir",
    legendAccountMoney: "Dinheiro em conta",
    legendCreditCard: "Cart\xE3o de Cr\xE9dito ou D\xE9bito",
    mainHeader: "Pagamento Finalizado",
    mainHeaderDelivery: "Envio",
    mainHeaderError: "Pagamento Recusado",
    mainHeaderTakeaway: "Retirada na loja",
    required: "Este campo \xE9 obrigat\xF3rio",
    secondaryHeadingDelivery: "Dados de entrega em domic\xEDlio",
    secondaryHeadingDetail: "Detalhe da compra",
    secondaryHeadingTakeaway: "Dados de faturamento",
    shippingPrice: "Frete",
    stockErrorEssen: "Disculpe las molestias. Nos hemos quedado sin stock.",
    stockErrorTwoEssen: "Por favor contacta a tu emprendedor amigo para coordinar la compra.",
    stockErrorTN: "Disculpe las molestias. Nos hemos quedado sin stock.",
    stockErrorTNTwo: "Explora otras opciones disponibles en el cat\xE1logo de productos.",
    postalCodeError: "El c\xF3digo postal es incorrecto. Int\xE9ntelo nuevamente con los siguientes c\xF3digos: ",
    summaryLine: "Valor Total",
    takeawayText: "Uma vez finalizado o processo de pagamento, voc\xEA receber\xE1 um comprovante de compra por e-mail.",
    text: "Obrigado por viver a experi\xEAncia da Live Shopping com ",
    textCash: "Voc\xEA receber\xE1 um e-mail para coordenar o pagamento",
    textCashError: "Ocorreu um problema.",
    textError: "Aconteceu um erro com o pagamento.",
    textErrorTwo: "Tente novamente",
    thanksCash: "\xA1Obrigado pela sua compra!",
    wrongEmailFormat: "Introduza um endere\xE7o de correio electr\xF3nico v\xE1lido",
    linxRedirecting: "Um momento por favor...",
    fillUserData: "Preencha seus dados",
    wrongResellerNumberFormat: "N\xFAmero de conta inv\xE1lido",
    wrongZoneNumberFormat: "N\xFAmero de zona inv\xE1lido "
  },
  countdown: {
    addToCalendarButton: "Adicionar ao meu calend\xE1rio",
    eventName: "Live Shopping",
    eventDescriptionTextOne: "Participe do evento Live Shopping de",
    eventDescriptionTextTwo: "Voc\xEA poder\xE1 saber mais sobre os produtos, fazer consultas e comprar na mesma hora.",
    finishEventTimerHeader: "O evento ao vivo continua por mais algum tempo.",
    finishEventTimerSecondaryHeader: "O evento termina em:",
    monthNames: {
      january: "Janeiro",
      february: "Fevereiro",
      march: "Mar\xE7o",
      april: "Abril",
      may: "Maio",
      june: "Junho",
      july: "Julho",
      august: "Agosto",
      september: "Setembro",
      october: "Outubro",
      november: "Novembro",
      december: "Dezembro"
    },
    startEventTimerHeader: "Estamos prestes a come\xE7ar:"
  },
  dialogs: {
    acceptButton: "Aceitar",
    alternateHeadingError: "Aconteceu um erro com o pagamento.",
    alternateHeadingPending: "Pagamento pendente de confirma\xE7\xE3o",
    alternateHeadingSuccess: "Obrigado por viver a experi\xEAncia de Live Shopping com ",
    autoPlayUnlocker: {
      dialogContentText: "Toque para ativar o som"
    },
    blockUser: "Bloquear usu\xE1rio",
    browserDescription: "Por favor, tente usando o Safari",
    browserTitle: "Navegador n\xE3o suportado",
    buyerPoorNetworkDescription: "A sua liga\xE7\xE3o \xE0 rede \xE9 inst\xE1vel. A aplica\xE7\xE3o pode n\xE3o funcionar correctamente.",
    cancel: "Voltar ao evento",
    cancelButton: "Cancelar",
    cannotDeleteIsPinned: "As mensagens fixadas n\xE3o podem ser exclu\xEDdas",
    cannotDeleteHasAnswers: "As mensagens com respostas n\xE3o podem ser exclu\xEDdas",
    closeButton: "Fechar",
    configurationHeader: "Configuration",
    configurationSubtitle: "Moderator",
    configurationActionItemOne: "Ativar pop-up promo\xE7\xE3o",
    configurationActionItemTwo: "Ativar counter come\xE7o",
    configurationActionItemThree: "Ativar counter fechamento",
    confirm: "Sair",
    content: "Tem certeza que deseja sair do evento?",
    customDialog: "O vendedor finalizou a liga\xE7\xE3o",
    deniedPermitsTitle: "Permiss\xF5es pendentes",
    dialogTitle: "Finalize sua compra",
    goBackButton: "Voltar a LiveShopping",
    goToCheckout: "Ser\xE1 redireccionado para o e-commerce da {{storeName}} para continuar o processo.",
    joinChatErrorDescription: "Recarregue o evento.",
    leaveButton: "Sair do evento",
    muteUser: "Silenciar Usuario",
    payPalDialog: "Voc\xEA ser\xE1 redirecionado para uma janela pop-up para concluir o pagamento pelo PayPal.",
    pendingPermitsTitle: "Permiss\xF5es pendentes",
    permitsDescription: " GoJiraf precisa de permiss\xE3o para acessar o microfone",
    termsAndConditionsBr: "Leia os Termos e Condi\xE7\xF5es e aperte aceitar para continuar.",
    termsAndConditionsBrTitle: "Termos e Condi\xE7\xF5es"
  },
  errorMessage: {
    defaultErrorMessageTitle: "Houve um erro inesperado no chat.",
    defaultErrorMessage: "Ocorreu um problema..",
    joinErrorMessage: "Recarregar o evento.",
    pinErrorMessage: "Ocorreu um erro ao fixar a mensagem. Tente novamente.",
    muteErrorMessage: "Ocorreu um erro ao silenciar o participante. Tente novamente.",
    sendMessageError: "Ocorreu um erro ao enviar a mensagem. Tente novamente.",
    badWord: "N\xE3o pudemos enviar sua mensagem pois infringe nossos termos e condi\xE7\xF5es e queremos que a comunica\xE7\xE3o se mantenha de maneira respeitosa.",
    badWordTitle: "Mensagem n\xE3o enviada",
    unpinMessageError: "Ocorreu um erro ao desafixar a mensagem. Por favor, tente novamente.",
    deletedChannel: "Ocorreu um erro inesperado no chat \u{1F614}\u{1F622} | Internal Server Error - Status 500 - getStream"
  },
  homePage: {
    calling: "Chamando...",
    countdownTimerDays: "Dias",
    countdownTimerHours: "Horas",
    countdownTimerMinutes: "Minutos",
    countdownTimerSeconds: "Segundos",
    defaultError: "Ops, ocorreu um erro. Por favor, tente novamente mais tarde",
    errorTypography: "Erro no servidor",
    join: "Entrar",
    lengthErrorName: "O nome deve ter entre 2 e 25 caracteres",
    loadercontainerTypography: "Voc\xEA est\xE1 prestes a entrar",
    micAndCamContainer: {
      parragraphCaseOne: "Voc\xEA poder\xE1 ativ\xE1-lo quando quiser (mic ou cam)",
      parragraphCaseTwo: "Voc\xEA poder\xE1 ativ\xE1-lo quando quiser (mic ou cam)",
      parragraphDefault: "Voc\xEA pode ativ\xE1-los quando quiser (mic e cam)",
      textCaseOne: "Sua c\xE2mera e microfone est\xE3o ativados",
      textCaseThree: "Seu microfone est\xE1 desativado",
      textCaseTwo: "Sua c\xE2mera est\xE1 desativada",
      textDefault: "Sua c\xE2mera e microfone est\xE3o desativados"
    },
    moderator: "Modera\xE7\xE3o do Bate-papo",
    cohost: "Convidado",
    nickname: "Compartilhe seu nome ou apelido",
    nicknameLabel: "Nome ou Apelido",
    phoneContainerCustomDialogTitle: "Seu Personal Shopper saiu",
    phoneContainerCustomDialogDescription: "Parece que seu personal shopper est\xE1 com problemas de conex\xE3o. Voc\xEA pode fechar esta mensagem e esperar por eles",
    pin: "PIN",
    pinForgotten: "Esqueceu seu PIN?",
    pinLabel: "PIN Moderador",
    profaneName: "Nome impr\xF3prio",
    sellerNotAvailable: {
      additionalText: "\xA1N\xE3o perca os pr\xF3ximos evento ",
      button: "Voltar",
      customLink: "Contate-nos",
      customLinkOTM: "Loja Virtual",
      eventIsFullTitle: "O evento atingiu o n\xFAmero m\xE1ximo de participantes. Siga-nos nas redes sociais para ficar informado sobre os pr\xF3ximos eventos de Live Shopping.",
      eventStartedParagraph: "Atualize a p\xE1gina ou clique no bot\xE3o abaixo e n\xE3o perca a oportunidade de vivenciar um evento de Live Shopping ao vivo.",
      eventStartedTitle: "O evento j\xE1 come\xE7ou!",
      eventNotStartedParagraph: "Siga-nos nas redes sociais para ficar por dentro dos pr\xF3ximos eventos de Live Shopping",
      eventNotStartedTitle: "Neste momento n\xE3o h\xE1 nenhum evento ao vivo acontecendo",
      goToEvent: "Ir ao evento",
      goToHome: "Ir \xE1 home",
      link: "Deixe-nos suas informa\xE7\xF5es e entraremos em contato o mais r\xE1pido poss\xEDvel",
      linkOTM: "Te convidamos a continuar comprando em nossa loja",
      meetingFullHeading: "A reuni\xE3o chegou ao limite de participantes.",
      meetingFullHeadingOTM: "Obrigada pela participa\xE7\xE3o",
      meetingfullParagraphOTM: "o Evento chegou ao limite m\xE1ximo de participantes",
      phoneNumber: "Agende um atendimento",
      phoneNumberAndLink: "Envie-nos um Whatsapp ou deixe seus dados, que em breve entraremos em contato",
      sellerNotAvailableHeading: "Neste momento n\xE3o podemos atend\xE9-lo. Por favor, tente novamente mais tarde.",
      whatsappButton: "Via Whatsapp"
    },
    subtitle: "Voc\xEA est\xE1 prestes a entrar",
    useBeforeUnload: "Ao fechar a p\xE1gina, finalizar\xE1 a liga\xE7\xE3o"
  },
  moderatorPage: {
    wrongPin: "Pin inv\xE1lido"
  },
  products: {
    buy: "comprar",
    from: "desde",
    installments: "Parcelas de",
    highlightProduct: "igula",
    noResultsFound: "Sua busca n\xE3o encontrou nenhum resultado.",
    products: "Produtos",
    productsList: "Lista de Produtos",
    "more variants": "Mais op\xE7\xF5es",
    productListEmpty: "Nenhum produto foi carregado",
    searchBar: "Procurar produtos",
    viewOtherProducts: "consulte outros produtos",
    viewProduct: "ver mais",
    addToCart: "Adicionar"
  },
  pdp: {
    add: "adicionar",
    addToCart: "Adicionar",
    checkout: "Comprar",
    freeShipping: "Frete gr\xE1tis para todo o pa\xEDs",
    errorMessageVariants: "Selecione para continuar",
    description: "Descri\xE7\xE3o do produto",
    lightIntegration: "ver mais",
    products: "Produto"
  },
  uiElements: {
    copyToClipboard: "Copiado para \xE1rea de Transfer\xEAncia",
    shareMobile: "Compartilhado com sucesso",
    PIPBackground: "O v\xEDdeo est\xE1 sendo reproduzido no modo picture-in-picture (PIP)"
  },
  form: {
    satisfactionEvent: {
      eventStore: "Loja de eventos",
      company: "Company",
      send: "Enviar",
      reset: "Reset",
      passLeyend: "Nunca envie senhas atrav\xE9s do Google Forms.",
      leyend: "Este formul\xE1rio foi criado no GoJiraf\xAE.",
      forms: "Formul\xE1rios",
      placeHolderResponse: "Escreva sua resposta aqui",
      errorMessage: "Este campo \xE9 obrigat\xF3rio"
    }
  }
};

// src/translates/index.ts
var translations = {
  es: global_default,
  en: global_default2,
  pt: global_default3
};

// src/i18n.ts
import_i18next.default.use(import_react_i18next.initReactI18next).init({
  resources: {
    en: {
      translation: translations.en
    },
    es: {
      translation: translations.es
    },
    pt: {
      translation: translations.pt
    }
  },
  lng: "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false
  }
});
var i18n_default = import_i18next.default;

// src/index.ts
var src_default = i18n_default;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  translations
});
