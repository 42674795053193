import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ChatDesktopContainer } from './style'
import { useViews } from 'state'
import { useSelector } from 'react-redux'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { CALL_ACTION_CAMERAS, selectActiveCallAction } from '../../reducers/uiSlice'
import { ChatModule } from './ChatModule'
import { LaptopCameras } from '../GJKit/Cameras/LaptopCameras'
import { useAuth } from '@gojiraf/auth'
import AskNameDialog from '../dialogs/AskNameDialog'
import { useAmplifyChat } from '@gojiraf/chat'

export const ChatDesktop = ({ toggleMicrophone, toggleCamera }) => {
  const [chatOpen, setChatOpen] = useState(true)
  const { user } = useAuth()
  const { state, send } = useViews()
  const { isOneToManySale, storeConfigurations } = useSelector(selectCurrentStore)
  const activeCard = useSelector(selectActiveCallAction)
  const { state: chatState } = useAmplifyChat()

  useEffect(() => {
    if (activeCard === CALL_ACTION_CAMERAS) {
      send({ type: 'SHOW_PARTICIPANTS' })
    } else {
      send({ type: 'SHOW_CHAT' })
    }
  }, [])

  return (
    <ChatDesktopContainer>
      {!isOneToManySale && (
        <LaptopCameras
          style={{ display: state.matches('secondary.showingParticipants') === true ? '' : 'none' }}
          allowsMultipleBuyers={storeConfigurations.allowsMultipleBuyers}
          toggleMicrophone={toggleMicrophone}
          toggleCamera={toggleCamera}
        />
      )}
      {state.matches('secondary.showingChat') === true && (
        <ChatModule isDesktop chatOpen={chatOpen} setChatOpen={setChatOpen} user={user} />
      )}
      <AskNameDialog eventId={chatState.eventId} />
    </ChatDesktopContainer>
  )
}

ChatDesktop.propTypes = {
  isFetchingMoreMessages: PropTypes.bool,
  toggleMicrophone: PropTypes.func,
  toggleCamera: PropTypes.func,
}
